import React from 'react';
import '../styles/main.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faLine, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import lineQR from '../assets/line-qr.jpg';

function Contact() {
	const gowabiLink = "https://www.gowabi.com/en/venue/crystal-aura-massage-spa-18049";
	const googleMapsLink = "https://www.google.co.th/maps/place/Crystal+Aura+Massage+%E6%8C%89%E6%91%A9%E5%BA%97+%EB%A7%88%EC%82%AC%EC%A7%80%EC%83%B5/@18.7963239,98.9665,794m/data=!3m2!1e3!4b1!4m6!3m5!1s0x30da31284cc1161f:0x3753d1c821d30008!8m2!3d18.7963188!4d98.9690749!16s%2Fg%2F11y4r37db6?hl=en&entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D";

	return (
		<div className="contact">
			<h1>Contact Us</h1>
			<div className="booking-options">
				<h2>Booking Options</h2>
				<p>At Crystal Aura Massage & Spa, we offer multiple convenient ways for you to book your appointment:</p>
				<div className="booking-list">
					<a href={googleMapsLink} target="_blank" rel="noopener noreferrer" className="booking-item">
						<FontAwesomeIcon icon={faGoogle} />
						<span><strong>Google:</strong> Easily find us and read reviews on our Google Business profile.</span>
					</a>
					<div className="booking-item line-booking">
						<FontAwesomeIcon icon={faLine} />
						<div className="line-content">
							<span><strong>LINE:</strong> Scan our QR code or search @crystalauracnx to schedule your visit.</span>
							<img src={lineQR} alt="LINE QR Code" className="line-qr" />
						</div>
					</div>
					<a href="https://www.facebook.com/profile.php?id=61563111703990" target="_blank" rel="noopener noreferrer" className="booking-item">
						<FontAwesomeIcon icon={faFacebookF} />
						<span><strong>Facebook:</strong> Book an appointment through our Facebook page.</span>
					</a>
					<a href="https://www.instagram.com/crystalaura.cnx/" target="_blank" rel="noopener noreferrer" className="booking-item">
						<FontAwesomeIcon icon={faInstagram} />
						<span><strong>Instagram:</strong> Reach out to us on Instagram to reserve your spot.</span>
					</a>
					<a href={gowabiLink} target="_blank" rel="noopener noreferrer" className="booking-item">
						<FontAwesomeIcon icon={faCalendarAlt} />
						<span><strong>Gowabi:</strong> Use Gowabi to make your booking online.</span>
					</a>
					<a href="tel:0959932861" className="booking-item">
						<FontAwesomeIcon icon={faPhone} />
						<span><strong>Phone:</strong> Call us at 0959932861 to speak with us directly and book your session.</span>
					</a>
					<a href={googleMapsLink} target="_blank" rel="noopener noreferrer" className="booking-item">
						<FontAwesomeIcon icon={faMapMarkerAlt} />
						<span><strong>On-Site Visit:</strong> Drop by our location at 22/2 Nimmana Haeminda Rd Lane 13, Tambon Su Thep, Amphoe Mueang Chiang Mai, Chang Wat Chiang Mai 50200 to book your appointment in person.</span>
					</a>
				</div>
				<p className="welcome-message">We look forward to welcoming you to Crystal Aura Massage & Spa!</p>
			</div>
		</div>
	);
}

export default Contact;
