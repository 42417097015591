import React from 'react';
import '../styles/main.scss';

function Gallery() {
	const treatmentImages = [
		// Menu images first (these will be the first two images)
		{ src: require('../assets/menu1.jpg'), alt: "Crystal Aura Spa Menu Page 1", isMenu: true },
		{ src: require('../assets/menu2.jpg'), alt: "Crystal Aura Spa Menu Page 2", isMenu: true },
		// Regular treatment images
		{ src: require('../assets/1.jpg'), alt: "Thai Massage Treatment" },
		{ src: require('../assets/3.jpg'), alt: "Massage Therapy" },
		{ src: require('../assets/4.jpg'), alt: "Relaxation Session" },
		{ src: require('../assets/6.jpg'), alt: "Wellness Treatment" },
		{ src: require('../assets/7.jpg'), alt: "Spa Service" },
		{ src: require('../assets/8.jpg'), alt: "Therapeutic Massage" },
		{ src: require('../assets/9.jpg'), alt: "Crystal Therapy" },
		{ src: require('../assets/11.jpg'), alt: "Healing Session" },
		{ src: require('../assets/12.jpg'), alt: "Relaxation Therapy" },
		{ src: require('../assets/13.jpg'), alt: "Crystal Healing" },
		{ src: require('../assets/14.jpg'), alt: "Wellness Therapy" },
		{ src: require('../assets/15.jpg'), alt: "Massage Session" },
		{ src: require('../assets/16.jpg'), alt: "Treatment Session" },
		{ src: require('../assets/17.jpg'), alt: "Healing Treatment" },
		{ src: require('../assets/18.jpg'), alt: "Wellness Session" }
	];

	const specialtyImages = [
		{ src: require('../assets/19.png'), alt: "Spa Treatment", isMenu: true },
		{ src: require('../assets/20.png'), alt: "Specialty Treatment Room", isMenu: true },
		{ src: require('../assets/21.PNG'), alt: "Premium Spa Service" },
		{ src: require('../assets/22.PNG'), alt: "Exclusive Treatment" },
		{ src: require('../assets/23.PNG'), alt: "Special Therapy Room" },
		{ src: require('../assets/24.PNG'), alt: "VIP Treatment Area" },
		{ src: require('../assets/25.PNG'), alt: "Premium Service Room" },
		{ src: require('../assets/26.PNG'), alt: "Exclusive Spa Area" },
		{ src: require('../assets/27.PNG'), alt: "Special Treatment Space" },
		{ src: require('../assets/28.PNG'), alt: "Premium Therapy Room" },
		{ src: require('../assets/29.PNG'), alt: "VIP Service Area" }
	];

	const menuStyle = {
		gridColumn: 'span 2',
		maxWidth: '100%',
		margin: '0 auto'
	};

	return (
		<div className="gallery" style={{ backgroundColor: '#f6d9d5' }}>
			<section className="gallery-section">
				<h2>Our Treatments & Services</h2>
				<div className="gallery-grid">
					{treatmentImages.map((image, index) => (
						<div
							key={`treatment-${index}`}
							className="gallery-item"
							style={image.isMenu ? menuStyle : {}}
						>
							<img src={image.src} alt={image.alt} loading="lazy" />
						</div>
					))}
				</div>
			</section>

			<section className="gallery-section">
				<h2>Specialty Treatments</h2>
				<div className="gallery-grid">
					{specialtyImages.map((image, index) => (
						<div
							key={`specialty-${index}`}
							className="gallery-item"
							style={image.isMenu ? menuStyle : {}}
						>
							<img src={image.src} alt={image.alt} loading="lazy" />
						</div>
					))}
				</div>
			</section>
		</div>
	);
}

export default Gallery;
