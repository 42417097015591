import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import desktopBanner from '../assets/banner_desktop.png';
import mobileBanner from '../assets/banner_mobile.png';
import '../styles/main.scss';

function Header() {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<header className="header">
			<div className="top-banner">
				<div className="logo-container">
					<img src={desktopBanner} alt="Crystal Aura Spa Logo" className="logo desktop-logo" />
					<img src={mobileBanner} alt="Crystal Aura Spa Logo" className="logo mobile-logo" />
				</div>
				<nav className="desktop-nav">
					<ul>
						<li><Link to="/">Home</Link></li>
						<li><Link to="/services">Services</Link></li>
						<li><Link to="/gallery">Gallery</Link></li>
						<li><Link to="/contact" className="book-now">Book Now</Link></li>
					</ul>
				</nav>
				<button className={`mobile-menu-btn ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
					<span></span>
					<span></span>
					<span></span>
				</button>
			</div>

			<div className={`mobile-nav ${isOpen ? 'open' : ''}`}>
				<ul>
					<li><Link to="/" onClick={() => setIsOpen(false)}>Home</Link></li>
					<li><Link to="/services" onClick={() => setIsOpen(false)}>Services</Link></li>
					<li><Link to="/gallery" onClick={() => setIsOpen(false)}>Gallery</Link></li>
					<li><Link to="/contact" className="book-now" onClick={() => setIsOpen(false)}>Book Now</Link></li>
				</ul>
			</div>
		</header>
	);
}

export default Header;
